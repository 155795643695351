<template>
  <div>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-menu-lives-aluno-fast">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turmas'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turmas')"
              >Minhas turmas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '?matricula=' + base64encode(base64decode(getUrlParameter('matricula')))"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '?matricula=' + base64encode(base64decode(getUrlParameter('matricula'))))"
              >Turma {{ fastTurma.sigla_turma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">{{ fastPrograma.sigla_programa }}</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-ead-content mt-0">
        <div class="container">
          <div class="mt-3 mb-4">
            <a
              v-if="fastNavegacao.exibirModulos"
              class="btn-novo btn-red-hollow-novo fast-iuea-ml-20"
              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turmas'"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '?matricula=' + base64encode(base64decode(getUrlParameter('matricula'))))"
            >
              <b-icon-arrow-return-left /> Voltar
            </a>
            <a
              v-else-if="fastNavegacao.exibirAvaliacoesTurma || fastNavegacao.exibirLives"
              class="btn-novo btn-red-hollow-novo fast-iuea-ml-20"
              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turmas'"
              @click.prevent="fastNavegacao.exibirModulos = true, fastNavegacao.exibirLives = false, fastNavegacao.exibirAvaliacoesTurma = false"
            >
              <b-icon-arrow-return-left /> Voltar
            </a>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="row">
              <transition name="slide-fade">
                <div v-if="fastNavegacao.exibirModulos" class="row">
                  <!-- fd-app-welcome -->
                  <div class="fd-app-welcome-novo fast-plataforma-iuea-hide col-12 order-first"
                  >
                    <h2
                      class="aluno_font_color mb-0"
                    >
                      Turma
                      <span v-if="fastPrograma.carregandoUnidades">...</span>
                      <span v-else>{{ fastTurma.sigla_turma }}</span>
                    </h2>

                    <p                           
                      v-if="fastPrograma.carregandoUnidades"
                      class="aluno_font_color my-0"
                    >
                      Carregando...                              
                    </p>
                    <p
                      v-else
                      class="aluno_font_color my-0"
                    >
                      {{ fastTurma.nome_curso }} - <span>{{ fastPrograma.sigla_programa }}</span>
                    </p>
                    <div>
                      <img
                        :src="
                          require('@/assets/images/separador.png')
                        "
                      >
                    </div>
                  </div>
                  <!-- /fd-app-welcome -->
                  <div class="col-sm-12 col-md-12 col-lg-5">
                    <div class="col-12 mt-5">
                        <a
                          href="#" 
                          class="row btn-menu btn-menu-blue-turmas p-2 mb-4"
                          @click.prevent="alteraFastNavegacao('exibirLives')"
                        >
                          <img
                            class="ml-3"                                  
                            :src="require('@/assets/images/app/aulas-ao-vivo-btn.png')"
                          >
                          <h2 class="col-9 font-weight-normal">AULAS AO VIVO</h2>
                        </a>
                        <a
                          href="#" 
                          class="row btn-menu btn-menu-blue-turmas p-2 mb-4"
                          @click.prevent="alteraFastNavegacao('exibirAvaliacoesTurma')"
                        >
                          <img
                            class="ml-3"                                  
                            :src="require('@/assets/images/app/provas-btn.png')"
                          >
                          <h2 class="col-9 font-weight-normal">PROVAS</h2>
                        </a>
                      </div>
                  </div>   
                  <div class="col-2"></div>
                  <div class="col-sm-12 col-md-12 col-lg-5 align_col_minhas_provas">
                    <div class="col-12">
                      <a class="row justify-content-between btn-menu btn-menu-patio p-2 mb-4"
                        :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/patio-virtual'"
                        @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/patio-virtual')"
                      >
                        <img
                          :src="require('@/assets/images/app/patio-virtual.png')"
                          class="ml-3"
                        >
                        <h3 class="font-weight-normal">PÁTIO VIRTUAL</h3>
                        <img
                          :src="require('@/assets/images/app/status.png')"
                          class="mr-3"
                        >
                      </a>
                    </div>
                    <div v-if="!fastCalendarShow" class="col-12 text-center py-5">
                      <span>Carregando </span>
                      <b-icon-gear-fill class="text-orange" animation="spin-pulse" font-scale="1"/>
                    </div>
                    <FullCalendar v-else class='fd-app-calendar' :options='fastCalendarOptions'>
                      <template v-slot:eventContent='arg'>
                        <div @click.prevent="$router.push(arg.event.groupId).catch(e=>{})" id="tooltip-button-interactive" role="button" style="display: block; line-height:10px;" class="pl-2">
                          <b-icon-clipboard-check class="fd-app-calendar-icon" font-scale="1.1"/><br/>
                          <i>Avaliação</i>
                        </div>
                        <b-tooltip target="tooltip-button-interactive">
                          {{ arg.event.title }} 
                        </b-tooltip>
                      </template>
                    </FullCalendar>
                  </div>    
                  <div class="col-12 order-lg-1 order-first">                
                    <div
                      v-if="fastPrograma.carregandoUnidades"
                      class="row"
                    >
                      <div class="col-12">
                        <h5 class="text-secondary">Carregando unidades...</h5>
                      </div>
                    </div>
                    <div
                      v-else-if="fastPrograma.unidades.length"
                      class="row"
                    >
                      <div class="col-12">
                        <h5 v-if="fastPrograma.unidades.length" class="text-secondary">Lista de Unidades Curriculares ({{ fastPrograma.unidades.length }}) </h5>
                      </div>
                      
                      <div 
                        v-for="unidade in fastPrograma.unidades"
                        :key="unidade.id_curso"
                        class="fd-app-turma col-sm-12 col-md-12 col-lg-4 mb-4">
                        <div class="card">
                          <div class="card-header">
                            <div :style="{ 'background-image': 'url(' + imageSitePlataformaImg + ')', 'background-position' : 'center'}"></div>
                          </div>
                          <div class="card-body text-center">
                            <div class="progress position-relative mb-3">
                                <div class="progress-bar" role="progressbar" :style="{ width: unidade.porcentagem + '%'}" :aria-valuenow="unidade.porcentagem" aria-valuemin="0" aria-valuemax="100"></div>
                                <span class="justify-content-center d-flex position-absolute w-100">{{ unidade.porcentagem }}%</span>
                            </div>
                            <h5 class="mb-4">{{ unidade.nome_curso }}</h5>
                            <div class="mb-2">
                              <a 
                                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos/curso/' + unidade.id_curso + '?turma=' + base64encode(parseInt($route.params.id_turma)) + '&programa=' + base64encode(parseInt($route.params.id_programa_turma)) + '&matricula=' + base64encode(base64decode(getUrlParameter('matricula')))"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos/curso/' + unidade.id_curso + '?turma=' + base64encode(parseInt($route.params.id_turma)) + '&programa=' + base64encode(parseInt($route.params.id_programa_turma)) + '&matricula=' + base64encode(base64decode(getUrlParameter('matricula'))))"
                                class="btn-novo btn-primary"
                              >
                                ACESSAR CONTEÚDO
                              </a>
                            </div>
                            <div v-if="unidade.avaliacao_configurada && unidade.id_secretaria_avaliacao_principal" class="mb-2">
                              <a 
                                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '/programa/' + fastPrograma.id_programa_turma + '/avaliacao/' + unidade.id_secretaria_avaliacao_principal + '?tipo=A&matricula=' + base64encode(base64decode(getUrlParameter('matricula')))"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '/programa/' + fastPrograma.id_programa_turma + '/avaliacao/' + unidade.id_secretaria_avaliacao_principal + '?tipo=A&matricula=' + base64encode(base64decode(getUrlParameter('matricula'))))"
                                class="btn-novo btn-primary"
                              >
                                ACESSAR AVALIAÇÃO
                              </a>
                            </div>
                            <div v-if="unidade.recuperacao_configurada && unidade.id_secretaria_avaliacao_recuperacao && unidade.id_secretaria_avaliacao_aluno_avaliacao && !unidade.aprovado_avaliacao" 
                              class="mb-2"
                            >
                              <a 
                                href="#"
                                @click.prevent="acessarRecuperacao(unidade)"
                                class="btn-novo btn-primary"
                              >
                                ACESSAR RECUPERAÇÃO
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                            
                    </div>
                    <div
                      v-else
                      class="row"
                    >
                      <div class="col-12">
                        <h5 class="text-secondary">Nenhuma unidade curricular encontrada no módulo</h5>
                      </div>
                    </div> 
                  </div>
                </div>
              </transition>

              <transition name="slide-fade">
              <div v-if="fastNavegacao.exibirLives" class="col-sm-12 col-md-12 col-lg-12">
                <section
                  class="fd-app-welcome text-center"
                >
                  <h1
                    class="aluno_font_color"                            
                  >
                    Aulas 
                    <span>ao vivo</span>
                  </h1>   

                  <h4 class="mt-4">{{fastProgramacaoLives.length}} aulas encontrada(s)</h4>                         
                </section>

                <div class="row mt-2 mb-4">
                  <div v-for="(e, index) in fastProgramacaoLives" :key="index" class="col-sm-12 col-md-12 col-lg-12 p-2 mt-2">              
                    <b-card no-body class="shadow-sm border-none">
                      <b-row no-gutters>                                 
                        <b-col md="6" class="d-flex align-items-center text-center">
                          <b-card-body :title="e.titulo_aula" class="p-md-4 p-4 text-info">

                            <span class="text-xs font-weight-bold text-secondary" v-if="e.hora_programada">Horário: {{ e.hora_programada.slice(0, -3) }} </span>

                            <div v-if="!e.hora_passou && e.status_live != 'C' && !e.status_live == 'F'" class="card-text text-info mt-2 mb-2">
                              <small>Aguarde o horário...</small> 
                            </div> 
                            <div v-else-if="!e.id_live" class="card-text  text-danger mt-2 mb-2">
                              <small>A aula ainda não foi agendada. Aguarde...</small> 
                            </div>  
                            <div v-else-if="e.status_live == 'C'" class="card-text  text-danger mt-2 mb-2">
                              <small>A aula foi cancelada</small> 
                            </div>  
                            <div v-else-if="e.status_live == 'F'" class="card-text  text-danger mt-2 mb-2">
                              <small>A aula já foi finalizada</small> 
                            </div> 

                            <div>
                              <a v-if="!e.id_live" href="#" @click.prevent="exibeToasty('Acesso não autorizado', 'error')" class="btn btn-secondary disabled text-white p-2 mt-2">
                                <b-icon icon="door-open"></b-icon>  Entrar na sala
                              </a>
                              <a v-else-if="e.status_live == 'A' || e.status_live == 'E'" href="#" @click.prevent="verificaLiveTurma(e)" class="btn btn-primary text-white p-2 mt-2">
                                <b-icon icon="door-open"></b-icon>  Entrar na sala
                              </a>
                            </div>
                          </b-card-body>
                        </b-col>
                          <b-col md="6">

                              <div class="pt-md-4 pt-0 pb-4 pl-4 pr-4">
                                <span class="text-xs font-weight-bold text-secondary">Professor responsável</span>
                                <div v-if="e.professoresLoading" class="col-12 text-left mt-4">
                                  <b-icon
                                    icon="gear-fill"
                                    animation="spin"
                                  />
                                  Buscando professor
                                </div>
                                <b-list-group v-else-if="e.professores.length">
                                  <b-list-group-item v-for="(p, index) in e.professores" :key="index">
                                    <div :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(p.image) + ')', width: '60px', height: '60px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }" />                                                      
                                    <div class="text-center text-xs font-weight-bold text-secondary">Prof. {{p.nome_razao ? p.nome_razao : 'Sem nome'}} {{p.sobrenome_fantasia}} </div>
                                  </b-list-group-item>                                           
                                </b-list-group>
                                <div v-else class="col-12 text-left mt-4">
                                  Nenhum professor encontrado
                                </div>
                              </div>

                              <div v-if="e.professores.length && !e.tutor_professor && e.id_live" class="pt-md-4 pt-0 pb-4 pl-4 pr-4">
                                <span class="text-xs font-weight-bold text-secondary">Tutor</span>                                          
                                <b-list-group>
                                  <b-list-group-item>
                                    <div :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(e.image_tutor) + ')', width: '60px', height: '60px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }" />                                                      
                                    <div class="text-center text-xs font-weight-bold text-secondary">{{e.nome_tutor ? e.nome_tutor : 'Sem nome'}} {{e.sobrenome_tutor}} </div>
                                  </b-list-group-item>                                           
                                </b-list-group>
                              </div>
                        
                        </b-col>
                      </b-row>
                    </b-card>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <button
                      href="#"
                      type="button"
                      class="btn btn-secondary mr-1"
                      @click="alteraFastNavegacao('exibirModulos')"
                    >
                      <b-icon-arrow-return-left /> Voltar
                    </button>
                  </div>
                </div>

              </div>
              </transition>

              <transition name="slide-fade">
                <div v-if="fastNavegacao.exibirAvaliacoesTurma" class="col-12 mt-0 px-0">
                  <section class="fd-app-welcome-novo text-left fast-plataforma-iuea-hide">
                    <h2 class="aluno_font_color mb-0">
                      Minhas
                      <span>Provas</span>
                    </h2>
                    <p class="aluno_font_color my-0">
                      {{ fastTurma.nome_curso }}
                    </p>
                    <div>
                      <img :src="require('@/assets/images/separador.png')">
                    </div>
                  </section>
                  <div class="row mb-4">
                    <div class="col-12 text-left my-3">
                      <span v-if="fastAvaliacoesTurma.length"
                        class="text-xs font-weight-bold text-secondary text-uppercase">Avaliações encontradas</span>
                      <span v-else class="text-xs font-weight-bold text-secondary text-uppercase">Nenhuma avaliação
                        encontrada</span>
                    </div>
                    <div v-for="(e, index) in fastAvaliacoesTurma" :key="index"
                      class="fd-app-turma col-sm-12 col-md-12 col-lg-4 mb-4">
                      <div class="card">
                        <div class="card-header">
                          <div
                            :style="{ 'background-image': 'url(' + imageSitePlataformaImg + ')', 'background-position': 'center' }">
                          </div>
                        </div>
                        <div class="card-body text-center">
                          <h5 class="mb-4">{{ e.nome_curso ? e.nome_curso : e.nome_curso_mae }}</h5>
                          <div v-if="e.avaliacao_configurada && e.id_secretaria_avaliacao_principal">
                            <a :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '/programa/' + e.id_programa_turma + '/avaliacao/' + e.id_secretaria_avaliacao_principal + '?tipo=A&matricula=' + base64encode(base64decode(getUrlParameter('matricula')))"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '/programa/' + fastPrograma.id_programa_turma + '/avaliacao/' + e.id_secretaria_avaliacao_principal + '?tipo=A&matricula=' + base64encode(base64decode(getUrlParameter('matricula'))))"
                              class="btn-novo btn-primary mb-3">
                              Ir para avaliação
                            </a>
                          </div>
                          <div
                            v-if="e.recuperacao_configurada && e.id_secretaria_avaliacao_recuperacao && e.id_secretaria_avaliacao_aluno_avaliacao">
                            <a :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '/programa/' + e.id_programa_turma + '/avaliacao/' + e.id_secretaria_avaliacao_recuperacao + '?tipo=R&matricula=' + base64encode(base64decode(getUrlParameter('matricula')))"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '/programa/' + fastPrograma.id_programa_turma + '/avaliacao/' + e.id_secretaria_avaliacao_recuperacao + '?tipo=R&matricula=' + base64encode(base64decode(getUrlParameter('matricula'))))"
                              class="btn-novo btn-primary mb-3">
                              Ir para recuperação
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <!-- Modal -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import imageSitePlataformaImg from "@/assets/images/app/plataforma-img.jpg";
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'

export default {
  name: "HomeInternoAlunoTurmaPrograma",
  components: {
    FullCalendar
  },
  mixins: [methods],
  data: function () {
    return {
      imageSitePlataformaImg,
      fastTemplate: settings.fastTemplate,
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",    
      fastNavegacao: {
        exibirModulos: true,
        exibirAvaliacoesTurma: false,
        exibirLives: false,
      },
      fastTurma: {
        modulos: [],
        carregandoModulos: true
      },  
      fastPrograma: {
        id_programa_turma: 0,
        sigla_programa: "",
        unidades: [],
        carregandoUnidades: true
      },
      // Lives
      fastProgramacaoLives: [],
      fastDataAtual: "",
      // Avaliacoes
      fastAvaliacoesTurma: [],
      // Calendário
      fastCalendarOptions: {
        plugins: [
          dayGridPlugin,
        ],
        locale: 'pt-BR',
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'prevYear,nextYear'
        },
        buttonText: {
          today: "Hoje",
          month: "Mês",
          week: "Semana",
          day: "Dia",
          year: "Ano"
        },
        initialView: 'dayGridMonth',
        initialEvents: [
        ],
      },
      fastCalendarShow: false
    };
  },
  computed: { },
  mounted: function () {
    if (this.$route.params.id_plataforma && this.$route.params.id_turma && this.$route.params.id_programa_turma && this.getUrlParameter("matricula")) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          this.getFastCursosPrograma(this.$route.params.id_plataforma, this.$route.params.id_programa_turma)
          this.getFastMatriculaAluno(this.$route.params.id_plataforma, this.base64decode(this.getUrlParameter("matricula")))
          this.getFastProgramacaoLives(this.$route.params.id_plataforma, this.$route.params.id_turma) 
          this.getAvaliacoesCalendario()
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
        this.exibeToasty("Sem permissões", "error");
        this.$store.state.fastCarregando = false;
    }
  },
  methods: { 
    async getFastMatriculaAluno(id_plataforma, id_matricula){
      this.promiseGetFastApi("api/fast_secretaria_matricula_aluno/lista_aluno", "id_matricula=" + id_matricula + "&id_plataforma=" + id_plataforma).then(obj => {
        console.log("getFastMatriculaAluno", obj)
        this.fastMatriculaAluno = obj;
        this.getFastTurma(this.$route.params.id_plataforma, this.$route.params.id_turma)
      }).catch(e => {
        console.log(e);
      })
    },
    async getFastTurma(id_plataforma, id_turma) {
      this.fastTurma.carregandoModulos = true;
      this.promiseGetFastApi("api/fast_secretaria_turma/lista", "id_plataforma=" + id_plataforma + "&id_turma=" + id_turma).then(obj => {
        console.log("getFastTurma", obj)
        if (obj.length) {
          obj[0].modulos = []; 
          obj[0].carregandoModulos = true         
          this.fastTurma = obj[0]
        } else {
          this.fastTurma.carregandoModulos = false;  
        }
             
      }).then(() => {
        this.promiseGetFastApi("api/fast_secretaria_turma_programa/lista", "id_plataforma=" + id_plataforma + "&id_turma=" + id_turma).then(obj => {
            console.log("getFastMódulos", obj)
            if (obj.length) {          
              obj.forEach((m, index) => {                
                if (m.id_programa_turma == this.$route.params.id_programa_turma) {
                  this.fastPrograma.id_programa_turma = m.id_programa_turma
                  this.fastPrograma.sigla_programa = m.sigla_programa
                }
                this.fastMatriculaAluno.forEach(m2 => {
                  if (m.id_programa_turma == m2.id_programa_turma) {
                    obj[index].permitido = true                                        
                  }
                })
              })   
              this.fastTurma.modulos = obj
              
            } else {
              this.fastTurma.modulos = [];
            }          
            this.fastTurma.carregandoModulos = false;  
          }).catch(e => {
            console.log(e);
          })
      })      
      .catch(e => {
        console.log(e);
      })
    },  
    async getFastCursosPrograma(id_plataforma, id_programa_turma) {
      this.fastPrograma.carregandoUnidades = true;
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_secretaria_turma_programa_unidade/lista_avs?id_plataforma=" +
            id_plataforma +
            "&id_programa_turma=" +
            id_programa_turma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        console.log("api/fast_secretaria_turma_programa_unidade/lista_avs", json)
        let obj = Array.from(json);
        
        if (obj.length > 0) {
          obj.forEach((e) => {
            if (e.data_inicio) e.data_inicio = e.data_inicio.split("T")[0];
            if (e.data_final) e.data_final = e.data_final.split("T")[0];            
          });

          this.stepList = obj.map((e) => {
            return e.sequencia + " - " + e.nome_curso;
          });

          this.fastPrograma.unidades = obj;

          // Pegar porcentagem concluída
          const tempUnidadesComPorcentagem = this.fastPrograma.unidades.map(async (unidade) => {
            const porcentagem = await this.getPorcentagemUnidade(unidade.id_curso);
            unidade.porcentagem = porcentagem;
            return unidade;
          });
          this.fastPrograma.unidades = await Promise.all(tempUnidadesComPorcentagem);
          
        } else {
          this.fastPrograma.unidades = [];
        }
        this.fastAvaliacoesTurma = this.fastPrograma.unidades.filter(av => av.avaliacao_configurada && av.id_secretaria_avaliacao_principal)

        this.fastPrograma.carregandoUnidades = false;
        console.log("getFastCursosPrograma", this.fastPrograma)
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async acessarRecuperacao(e){
      
      this.$router.push('/plataforma/' + this.$route.params.id_plataforma + '/aluno/turma/' + this.fastTurma.id_turma + '/programa/' + this.fastPrograma.id_programa_turma + '/avaliacao/' + e.id_secretaria_avaliacao_recuperacao + '?tipo=R&matricula=' + this.base64encode(this.base64decode(this.getUrlParameter('matricula'))))      
    },
    validaUnidadePreRequisito(unidade){
      let liberada = false
      if (unidade.id_pai) {
        this.fastPrograma.unidades.forEach(u => {
          if (unidade.id_pai == u.id_secretaria_curso_programa_unidade) {
            console.log("A Unidade #" + u.id_curso + " " + u.nome_curso + " deve estar finalizado")

            if (u.avaliacao_configurada) {
              console.log("Unidade com avaliação")
              if (u.aprovado_avaliacao || u.aprovado_recuperacao) {
                console.log("Aluno aprovou na avaliação")
                liberada = true
              } else {
                console.log("Aluno não aprovou na avaliação")
              }
            } else {
              console.log("Unidade sem avaliação")

              if (u.unidade_encerrada) {
                liberada = true
                console.log("Unidade encerrada")
              } else {
                console.log("Unidade não encerrada")
              }
              
            }
          }
        })
      } else {
        liberada = true
      }

      return liberada
      
    },
    // Lives
    async getFastProgramacaoLives(id_plataforma, id_turma){
      this.promiseGetFastApi("api/fast_secretaria_turma_programacao_aula/lista_lives", "id_plataforma=" + id_plataforma + "&id_turma=" + id_turma + "&data_programado=").then(res => {
        return res        
      })      
      .then(lives => {
        if (lives.length) {           
          this.promiseGetFastApi("api/fast_usuario_curso_aula_atividade/lista_hora", "").then(res => {
            let obj = JSON.parse(res)
            return new Date(obj.dataAtual)
          })
          .then(res => {
            this.fastDataAtual = res

            lives.forEach(e => {
              e.professores = []
              e.professoresLoading = true
              e.tutor_professor = false
              this.getProfessoresLive(e)

              e.hora_passou = false
              if (e.hora_programada) {
                let dataLive = new Date(e.data_programado.split("T")[0] + " " + e.hora_programada)
                if (this.fastDataAtual > dataLive) {                  
                  e.hora_passou = true
                }
              } else {
                e.hora_passou = true
              }      


            }); 
          
            this.fastProgramacaoLives = lives
          })
        }
      })      
      .catch(e => {
        this.exibeToasty("Erro ao recuperar aulas", "error")
      })
    },
    async getProfessoresLive(live){     
      this.promiseGetFastApi("api/fast_secretaria_turma_programacao_aula_professor/lista", `id_plataforma=${this.$route.params.id_plataforma}&id_programacao_aula=${live.id_programacao_aula}`).then(res => {
        if (res.length) {
          res.forEach(e => {
            if (e.email == live.email_tutor) live.tutor_professor = true
          })
          live.professores = res
        }
        live.professoresLoading = false
      }).catch(e => {         
        this.exibeToasty("Erro ao buscar Professores", "error")
        live.professoresLoading = false
      })
    },
    verificaLiveTurma(live){
      this.$store.state.fastCarregando = true
      const fast_secretaria_turma_programacao_aula = {
        id_turma: live.id_turma,
        id_plataforma: this.$route.params.id_plataforma,
        id_live: live.id_live
      }
      this.promisePostFastApi(fast_secretaria_turma_programacao_aula, "api/fast_secretaria_turma_programacao_aula/insere_live").then((res) => { 
        if (res.length){            
          window.location.href = `/live/${live.id_live}`
        }     
      }).catch((e) => {
          this.exibeToasty("Erro ao acessar sala de aula", "error");
          this.$store.state.fastCarregando = false
      });
    },
    // Avaliacoes
    async getAvaliacoesCalendario(){       
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_aluno/lista_todas_avaliacoes",`id_plataforma=${this.$route.params.id_plataforma}&id_pessoa_aluno=${this.$store.state.fastPermissoes.id_pessoa}`)
      .then(res => {
        console.log('getAvaliacoes', res)
        if (res.length) {
          this.fastCalendarOptions.initialEvents = []
          res.forEach(a => {
            let dataInicio = new Date(a.data_inicio);
            dataInicio.setDate(dataInicio.getDate() + a.liberar_prazo);

            let avObj = {
              id: a.id_secretaria_avaliacao, 
              title: `${a.titulo_avaliacao} - ${a.nome_curso} - ${a.nome_secretaria_curso}`, 
              start: `${dataInicio.toISOString().replace(/T.*$/, '')}T00:00:00`,
              groupId: `/plataforma/${a.id_plataforma}/aluno/turma/${a.id_turma}?matricula=${this.base64encode(a.id_matricula)}`
            }                 
            this.fastCalendarOptions.initialEvents.push(avObj)
            this.fastCalendarShow = true;
          });
        } else {
          this.fastCalendarShow = true;
        }
      }).catch(e => {
        console.log(e)
        this.fastCalendarShow = true;
        this.exibeToasty(e, "error")
      })
    },
    // Estatísticas
    async getPorcentagemUnidade(id_curso) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_usuario_curso/verifica_certificado?id_curso=" +
            id_curso +
            "&id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          return obj[0].status.split(" ").pop();
        }
      } catch(e) {
        console.log(e)
      }
    },
  },
};
</script>

<style scope>
@media (min-width: 960px){
  .align_col_minhas_provas{
    margin-top: -5.5rem;
  }
}
</style>
